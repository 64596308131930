<template>
    <div class="timeline-container">
        <div v-for="item in items" :key="item.uuid" class="timeline-row" v-on:click="pickRow(item)">
            <li class="timeline-text">{{ item.name }}</li>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        items: null,
    },
    methods: {
        pickRow (item) {
            console.log(item);
            this.$emit('pickRow', item);
        }
    }
}
</script>
<style scoped>
.timeline-container {
    display: flex;
    flex-direction: column;
    border-start: solid 1px grey;
    padding-left: 10px;
}

.timeline-row {
    flex-grow: 1;
    cursor: pointer;
    transform: translateX(-14px);
}

.timeline-text {
    margin: 0px;
    margin-top: 2px;
    margin-bottom: 2px;
}
</style>