<template>
    <div>
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">{{ 'services.services' | trans }}</h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="javascript:void(0);" v-on:click="$router.push({name: 'dashboard'})">{{ 'app.home' | trans }}</a></li>
                            <li class="breadcrumb-item active">{{ 'services.services' | trans }}</li>
                        </ol>
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <div class="content">
            <search-component model="services" header="services.services" :params="{}">
                <template v-slot:labels>
                    <th>{{ 'services.name' | trans }}</th>
                    <th>{{ 'services.price' | trans }}</th>
                    <th>{{ 'services.limit' | trans }}</th>
                    <th>{{ 'services.used' | trans }}</th>
                    <th>{{ 'services.type' | trans }}</th>
                    <th>{{ 'services.returns' | trans }}</th>
                    <th>{{ 'services.details' | trans }}</th>
                </template>
                <template v-slot:columns="slotProps">
                    <td>
                        {{ slotProps.element.name }}
                    </td>
                    <td v-if="slotProps.element.price != 0">{{ slotProps.element.price }}</td>
                    <td v-else>
                        <span class="badge bg-info">{{ 'services.covered_by_contract' | trans }}</span>
                    </td>
                    <td v-if="slotProps.element.limit != -1">
                        {{ slotProps.element.limit }}
                    </td>
                    <td v-else>
                        <span class="badge bg-success">{{ 'services.unlimited' | trans }}</span>
                    </td>
                    <td v-if="slotProps.element.limit > 0">
                        <div class="progress progress-xs">
                            <div class="progress-bar progress-bar-danger" v-bind:style="'width:'+(slotProps.element.used / slotProps.element.limit)+'%'"></div>
                        </div>
                    </td>
                    <td v-if="slotProps.element.limit == 0">
                        <span class="badge bg-danger">{{ 'services.all_used' | trans }}</span>
                    </td>
                    <td v-if="slotProps.element.limit < 0">
                        <span class="badge bg-success">{{ 'services.unlimited' | trans }}</span>
                    </td>
                    <td>
                        <!--First Mile-->
                        <p v-if="slotProps.element.type == 1" data-toggle="tooltip" data-placement="top" :title="$options.filters.trans('services.firstMileExplenation')">
                            <font-awesome-icon icon="fa-solid fa-box"/> <font-awesome-icon icon="fa-solid fa-long-arrow-alt-right"/> <font-awesome-icon icon="fa-solid fa-truck"/>
                        </p>
                        <!--Last Mile-->
                        <p v-if="slotProps.element.type == 2" data-toggle="tooltip" data-placement="top" :title="$options.filters.trans('services.lastMileExplenation')">
                            <font-awesome-icon icon="fa-solid fa-truck"/> <font-awesome-icon icon="fa-solid fa-long-arrow-alt-right"/>  <font-awesome-icon icon="fa-solid fa-box"/> 
                        </p>
                        <!--Storage-->
                        <p v-if="slotProps.element.type == 3" data-toggle="tooltip" data-placement="top" :title="$options.filters.trans('services.storageExplenation')">
                            <font-awesome-icon icon="fa-solid fa-box"/>
                        </p>
                        <!--End2End-->
                        <p v-if="slotProps.element.type == 4" data-toggle="tooltip" data-placement="top" :title="$options.filters.trans('services.endToEndExplenation')">
                            <font-awesome-icon icon="fa-solid fa-box"/> <font-awesome-icon icon="fa-solid fa-long-arrow-alt-right"/> <font-awesome-icon icon="fa-solid fa-box"/>
                        </p>
                    </td>
                    <td>
                        <font-awesome-icon v-if="slotProps.element.free_return" icon="fa-solid fa-undo-alt text-green" data-toggle="tooltip" data-placement="top" :title="$options.filters.trans('services.returnFree')"/>
                        <font-awesome-icon v-else icon="fa-solid fa-undo-alt text-gray" data-toggle="tooltip" data-placement="top" :title="$options.filters.trans('services.returnNotFree')"/>
                    </td>
                    <td>
                    <button type="button" class="btn">
                        <router-link style="color: inherit; text-decoration: none;" :to="'/services/' + slotProps.element.uuid">
                            <font-awesome-icon icon="fa-solid fa-info-circle"/>
                        </router-link>
                    </button>
                    </td>
                </template>
            </search-component>
        </div>
    </div>
</template>

<script>
import SearchComponent from '../../components/SearchComponent.vue';

export default {
    components: {
        SearchComponent,
    },
    data: function () {
        return {
            
        }
    },
    props: {
        
    },
    methods: {
        
    },
    computed: {
        
    },
    watch: {
        
    },
    created () {
        
    },
    mounted () {
        
    },
}
</script>