<template>
    <div class="container">
        <nav class="navbar navbar-expand-lg bg-body-tertiary">
            <div class="container-fluid">
                <a class="navbar-brand">
                    <span class="logo-text">
                        <router-link class="remove-style" to="/">
                            <b>Self</b>
                            Locker
                        </router-link>
                    </span>
                </a>

                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                            <a class="navbar-button"><router-link class="remove-style" to="/packages/create"><font-awesome-icon icon="fa-solid fa-plus"/>{{ 'main-navbar.send_package' | trans }}</router-link></a>
                        </li>
                        <li class="nav-item">
                            <a class="navbar-button"><router-link class="remove-style" to="/packages"> <font-awesome-icon icon="fa-solid fa-box"/> {{ 'main-navbar.packages' | trans }}</router-link></a>
                        </li>
                        <li class="nav-item">
                            <a class="navbar-button"><router-link class="remove-style" to="/payments"> <font-awesome-icon icon="fa-solid fa-wallet"/> {{ 'main-navbar.payments' | trans }}</router-link></a>
                        </li>
                        <li class="nav-item">
                            <a class="navbar-button"><router-link class="remove-style" to="/services"> <font-awesome-icon icon="fa-solid fa-truck"/> {{ 'main-navbar.services' | trans }}</router-link></a>
                        </li>
                    </ul>
                    <form class="d-flex">
                        <a v-if="entity" class="navbar-button" href="/select-entity"><font-awesome-icon icon="fa-solid fa-briefcase "/> {{ entity.name }}</a>
                        <a class="navbar-button"><router-link class="remove-style" to="/entities"> <font-awesome-icon icon="fa-solid fa-fw fa-user-cog "/> {{ 'main-navbar.settings' | trans }}</router-link></a>
                        <a v-on:click="logout" class="navbar-button" href="javascript:void(0);"> <font-awesome-icon icon="fa-solid fa-fw fa-power-off"/> {{ 'main-navbar.logout' | trans }}</a>
                    </form>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
export default {
    data () {
        return {
            entity: null,
        }
    },
    methods: {
        loadEntity () {
            let self= this;
            axios.get(this.$root.route('ajax.entities.index'))
            .then(function (response) {
                self.entity = response.data.data;
            })
        },
        logout () {
            axios.post('/logout')
            .then(response => {
                location.reload();
            })
        }
    },
    mounted () {
        this.loadEntity();
    }
}
</script>

<style lang="scss" scoped>
    @import 'resources/sass/_navbar.scss';
    .remove-style {
        text-decoration: none;
        color: inherit;
    }
</style>