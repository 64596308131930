<template>
    <div class="container mt-3">
        <calendar is-expanded/>
    </div>
</template>
<script>
import Calendar from 'v-calendar/lib/v-calendar.umd.js'

export default {
    components: {
        Calendar,
    },
}
</script>
