<template>
    <v-select 
        name="country"
        label="name" 
        :filterable="false" 
        :options="countries" 
        v-model="country"
        :class="stateClassName ? stateClassName : ''"
        @search="onSearchCountries"
    >
        <template #option="{ name }">
            {{name}}
        </template>
    </v-select>
</template>

<script>
    export default {
        data: function () {
            return {
                countries: [],
                country: null,
            }
        },
        props: {
            value: null,
            stateClassName: null
        },
        methods: {
            onSearchCountries(search, loading) {
                loading(true);
                this.searchCountries(loading, search, this);
            },
            searchCountries: _.debounce((loading, search, vm) => {
                axios.get(vm.$root.route('ajax.countries.index'), {
                            params: {
                                'filter[name]': search
                            }
                        }).then(function (response) {
                            vm.countries = response.data;
                            loading(false);
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
            }, 350),
        },
        watch: {
            country: function () {
                this.$emit('input', this.country);
            },
            value: function () {
                this.country = this.value;
            },
        },
        mounted () {
            this.country = this.value;
        }
    }
</script>
<style lang="scss">
.invalid > .vs__dropdown-toggle {
    border-color: red;
}
</style>