<template>
    <div class="">
        <div class="content-header">
            <div class="container-fluid m-0 p-0">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">{{ 'payments.payments' | trans }}</h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="javascript:void(0);" v-on:click="$router.push({name: 'dashboard'})">{{ 'app.home' | trans }}</a></li>
                            <li class="breadcrumb-item active">{{ 'payments.payments' | trans }}</li>
                        </ol>
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <div class="content">
            <div class="row d-flex justify-content-between mb-4">
                <div class="col-sm-7">
                    <div class="card mb-0">
                        <div class="card-body">
                            <ul class="nav nav-pills" v-if="generateChart">
                                <li class="nav-item">
                                    <a class="nav-link active" id="allPayments-tab" data-bs-toggle="tab" href="#allPayments" role="tab" aria-controls="allPayments" aria-selected="true">{{ this.$options.filters.trans('payments.all_payments') }}</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="balanceOperations-tab" data-bs-toggle="tab" href="#balanceOperations" role="tab" aria-controls="balanceOperations" aria-selected="false">{{ this.$options.filters.trans('payments.balance_operations') }}</a>
                                </li>
                            </ul>
                            <div class="tab-content" v-if="generateChart">
                                <div class="tab-pane fade show active" id="allPayments" role="tabpanel" aria-labelledby="allPayments-tab">
                                    <chart-component :dataSetArray="dataSetArray1" :labels="labels"></chart-component>
                                </div>
                                <div class="tab-pane fade" id="balanceOperations" role="tabpanel" aria-labelledby="balanceOperations-tab">
                                    <chart-component :dataSetArray="dataSetArray2" :labels="labels"></chart-component>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!-- /.col -->
                <div class="col-sm-4">
                    <div class="h-100 d-flex flex-column align-content-stretch">
                        <div class="card p-4">
                            <div class="card-body">
                                <h4 v-if="entity">{{ 'payments.balance' | trans }}: {{ entity.balance }} </h4>
                                <button type="button" class="btn btn-primary" v-on:click="$router.push({ name: 'payments-create'})">{{ 'payments.add_balance' | trans }} <font-awesome-icon icon="fa-solid fa-plus"/></button>
                            </div>
                        </div>
                        <div class="flex-grow-1">
                            <div class="card h-100">
                                <div class="card-header">
                                    {{ this.$options.filters.trans('payments.statistics') }}
                                </div>
                                <div class="card-body">
                                    <h6 v-for="status in statusesCount" class="mb-4"><span :class="'badge ' + status.status_name">{{ 'payments.' + status.status_name |trans }}</span> - {{ status.statuses_count }}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!-- /.col -->
            </div><!-- /.row -->
            <search-component ref="searchComponent" model="payments" header="header.payments" :params="{ paginate: true }" :includeCollapse="false" :includeFilters="true" >
                <template v-slot:searchCollapse="slotProps">
                    <div class="mt-4 d-flex justify-content-start">
                        <div class="me-2 text-center">
                            <label class="form-label">{{ 'payments.' + filters[0].label | trans }}</label>
                            <input v-model="filters[0].value" type="date" class="form-control">      
                        </div>
                        <div class="me-2 d-flex flex-column justify-content-end">
                            <label class="text-center">{{ 'payments.statuses' | trans }}</label>
                            <div v-if="statusesforSearch" class="dropdown" id="statusesDropdown">
                                <button class="btn btn-primary dropdown-toggle" type="button" id="statusDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                    {{ 'payments' + '.' + pickedStatus.name | trans }}
                                </button>
                                <ul class="dropdown-menu" aria-labelledby="statusDropdown">
                                    <li v-for="status in statusesforSearch" :key="status.name">
                                    <a class="dropdown-item" href="javascript:void(0);" @click="pickedStatus = status">{{ 'payments' + '.' + status.name | trans }}</a>
                                    </li>
                                    <li>
                                    <a class="dropdown-item" href="javascript:void(0);" @click="pickedStatus = { name: 'choose', ids: null }">{{ 'app.reset' | trans }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="me-4 d-flex flex-column justify-content-end col-2 px-0">
                            <label class="text-center">{{ 'payments.range' | trans }}</label>
                            <div class="d-flex justify-content-between">
                                <input v-model="filters[1].value" class="form-control col-5" :placeholder="$options.filters.trans('payments.' + filters[1].label)">
                                <h4> - </h4>
                                <input v-model="filters[2].value" class="form-control col-5" :placeholder="$options.filters.trans('payments.' + filters[2].label)">
                            </div>   
                        </div>
                        <div class="d-flex flex-column justify-content-end">
                            <div>
                                <button type="button" class="btn btn-warning" v-on:click="resetFilters">{{ 'app.filters_reset' | trans }}</button>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-slot:labels>
                    <th>{{ 'payments.amount' | trans }}</th>
                    <th>{{ 'payments.status' | trans }}</th>
                    <th>{{ 'payments.type' | trans }}</th>
                    <th>{{ 'payments.created_at' | trans }}</th>
                    <th>{{ 'payments.updated_at' | trans }}</th>
                </template>
                <template v-slot:columns="slotProps">
                    <td>{{ slotProps.element.amount }}</td>
                    <td><span :class="'badge ' + slotProps.element.status_name">{{ 'payments.' + slotProps.element.status_name | trans }}</span></td>
                    <td>{{ 'payments.' + slotProps.element.type_name | trans }}</td>
                    <td>{{ slotProps.element.created_at }}</td>
                    <td>{{ slotProps.element.updated_at }}</td>
                </template>
                    <template v-slot:collapse="slotProps">
                </template>
            </search-component>
        </div>
    </div>
</template>

<script>
import SearchComponent from '../../components/SearchComponent.vue';
import ChartComponent from '../../components/ChartComponent.vue';

export default {
    components: {
        SearchComponent,
        ChartComponent,
    },
    data: function () {
        return {
            labels: [],
            dataNonBalancePayments:[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            dataBalanceTopUps:[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            dataBalancePayments:[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            paymentsTotal: null,
            balancePayments: null,
            balanceTopUps: null,
            generateChart: false,
            chartColor: '#79b9f8',
            entity: null,
            dataSetArray1: [],
            dataSetArray2: [],
            balanceChart: false,
            currentMonth: null,
            statusesCount: null,
            pickedStatus: { name: 'choose', ids: null },
            filters: [
                {
                    name: 'filter[date]',
                    value: null,
                    label: 'date', 
                },
                {
                    name: 'filter[min_amount]',
                    value: null,
                    label: 'from', 
                },
                {
                    name: 'filter[max_amount]',
                    value: null,
                    label: 'to', 
                },
            ],
            statusesforSearch: [
                {
                    name: 'STATUS_CREATED',
                    ids: "1",
                },
                {
                    name: 'STATUS_PENDING',
                    ids: "2",
                },
                {
                    name: 'STATUS_CANCELED',
                    ids: "3",
                },
                {
                    name: 'STATUS_PROCESSING',
                    ids: "4",
                },
                {
                    name: 'STATUS_COMPLETED',
                    ids: "5"
                },
            ]
        }
    },
    props: {

    },
    methods: {
        loadPaymentsHisotry: function() {
            this.paymentsTotal = null;
            let self = this;
            axios.get(this.$root.route('ajax.payments.total-graph-data'))
            .then(function (response) {
                self.paymentsTotal = response.data;
                self.parseDataForPaymentGraph();
            })
        },
        loadBalanceHistory: function() {
            this.balanceTotal = null;
            let self = this;
            // Top-ups of balance
            axios.get(this.$root.route('ajax.payments.balance-graph-data'), {
                params: {
                        'type': 'topUps',
                    }
            })
            .then(function (response) {
                self.balanceTopUps = response.data;

                // Payments with balance 
                axios.get(self.$root.route('ajax.payments.balance-graph-data'), {
                params: {
                        'type': 'payments',
                    }
                })
                .then(function (response) {
                    self.balancePayments = response.data;
                    self.parseDataForBalanceGraph();
                })
            })
        },
        loadEntityBalance: function() {
            let self= this;
            axios.get(this.$root.route('ajax.entities.index'))
            .then(function (response) {
                self.entity = response.data.data;
                self.loadPaymentsHisotry();
                self.loadBalanceHistory();
            })
        },
        parseDataForPaymentGraph: function() {
            for (var j = 0; j < this.paymentsTotal.length; j++){
                this.dataNonBalancePayments[this.$root.changeMonthToRelative(parseInt(this.paymentsTotal[j].date_part) + this.currentMonth)] = this.paymentsTotal[j].amount_sum;
            }
            this.dataSetArray1.push({
                label: [this.$options.filters.trans('payments.all_payments')],
                backgroundColor: this.chartColor,
                data: this.dataNonBalancePayments,
            })
        },
        parseDataForBalanceGraph: function() {
            // Parse Payments
            for (var j = 0; j < this.balancePayments.length; j++){
                // rearrange the values, the value for the current month is the last
                this.dataBalancePayments[this.$root.changeMonthToRelative(parseInt(this.balancePayments[j].date_part) + this.currentMonth)] = this.balancePayments[j].amount_sum;
            }
            this.dataSetArray2.push({
                label: [this.$options.filters.trans('payments.balance_payments')],
                backgroundColor: this.chartColor,
                data: this.dataBalancePayments,
            })
            // Parse TopUps
            for (var j = 0; j < this.balanceTopUps.length; j++){
                // rearrange the values, the value for the current month is the last
                this.dataBalanceTopUps[this.$root.changeMonthToRelative(parseInt(this.balanceTopUps[j].date_part) + this.currentMonth)] = this.balanceTopUps[j].amount_sum;
            }
            this.dataSetArray2.push({
                label: [this.$options.filters.trans('payments.topups')],
                backgroundColor: this.chartColor,
                data: this.dataBalanceTopUps,
            })
            this.generateChart = true; 
        },
        fillMonthsArray: function() {
            this.labels = this.$root.getMonthsArray();
            
            var tmpLabels = [];
            // we rearrange the array, current month is last
            for (let i = 0; i < this.labels.length; i++) {
                tmpLabels[this.$root.changeMonthToRelative(i + this.currentMonth + 1)] = this.labels[i];
            }
            this.labels = tmpLabels;
            this.loadEntityBalance();
        },
        loadStatusesStatistics: function() {
            let self= this;
            axios.get(this.$root.route('ajax.payments.statuses-count'))
            .then(function (response) {
                self.statusesCount = response.data;
            })
        },
        resetFilters: function() {
            this.pickedStatus = { name: 'choose', ids: null };
            for (let i = 0; i < this.filters.length; i++) {
                this.filters[i].value = null;
            }
        },
    },
    computed: {
        filterDate: function() {
            return this.filters[0].value;
        },
        filterMinAmount: function() {
            return this.filters[1].value;
        },
        filterMaxAmount: function() {
            return this.filters[2].value;
        }
    },
    mounted() {
        this.currentMonth = new Date().getMonth();
        this.fillMonthsArray();
        this.loadStatusesStatistics();
    },
    watch: {
        filterDate: function() {
            setTimeout(() => {
                this.$refs.searchComponent.buildRequest(this.filters[0]);
            }, 400);
        },
        filterMinAmount: function() {
            setTimeout(() => {
                this.$refs.searchComponent.buildRequest(this.filters[1]);
            }, 400);
        },
        filterMaxAmount: function() {
            setTimeout(() => {
                this.$refs.searchComponent.buildRequest(this.filters[2]);
            }, 400);
        },
        "pickedStatus.ids": function() {
            this.$refs.searchComponent.buildRequest({name: 'filter[status]', value: this.pickedStatus.ids});
        }
    }
}
</script>
<style scoped lang="scss">
@import 'resources/sass/_payment-statuses.scss';
</style>