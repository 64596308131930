<template>
    <div>
        <modal :name="name" width="75%" height="75%">
            <vl-map :load-tiles-while-animating="true" :load-tiles-while-interacting="true">
                <vl-view :zoom.sync="zoom" :center.sync="center" :rotation.sync="rotation" projection="EPSG:4326"></vl-view>

                <vl-layer-tile>
                    <vl-source-osm></vl-source-osm>
                </vl-layer-tile>

                <div v-for="(machine, index) in data">
                    <vl-overlay :position="[ machine.latitude, machine.longitude ]" style="cursor:pointer">
                        <template slot-scope="scope">
                            <p :id="'machine' + _uid + '-' + index">
                                <font-awesome-icon icon="fa-solid fa-map-pin text-green" v-if="selected == machine.name"/>
                                <font-awesome-icon icon="fa-solid fa-map-pin" v-else/>
                            </p>
                            
                            <button type="button" class="btn btn-secondary" data-bs-container="body" data-bs-toggle="popover" data-bs-placement="top" data-bs-content="Top popover">
                                <p class="text-uppercase text-center"><b>{{ machine.name }}</b></p>
                                <div>
                                    <p>{{ machine.street }} {{ machine.building_number }} / {{ machine.apartment_number }}</p>
                                    <p>{{ machine.zip_code }} {{ machine.city }}</p>
                                    <p>{{ 'machines.opening_hours' | trans }}: {{ machine.opening_hours }}</p>
                                    <p></p>
                                    <p>{{ machine.description }}</p>
                                    <p class="text-center" v-if="selected != machine.name"><button type="button" class="btn btn-primary form-control" v-on:click="select(machine)">{{ 'machines.choose' | trans }}</button></p>
                                    <p class="text-center" v-else><button type="button" class="btn btn-primary form-control" disabled>{{ 'machines.selected' | trans }}</button></p>
                                </div>
                            </button>
                        </template>     
                    </vl-overlay>
                </div> 

            </vl-map>
        </modal>
        <select class="form-control" @click="openSelect($event)" :disabled="disabled">
                <option v-if="!this.service && !(!this.service_origin && !this.service_destination)" value="" disabled selected>{{ data.length }} {{ 'machines.to_choose' | trans }}</option>
                <option v-else disabled selected value="">{{ 'machines.choose_service_first' | trans }}</option>
                <option v-if="selected" selected>{{ selected }}</option>
        </select>
    </div>
</template>

<script>
export default {
    data: function () {
        return {
            data: [],
            center: [
                12,
                12
            ],
            rotation: 0,
            zoom: 4,
            selected: null,
            machine: null,
        }
    },
    props: {
        name: null,
        disabled: false,
        service: false,
        service_destination: null,
        service_origin: null,
        selectedMachine: null,
    },
    methods: {
        openSelect: function (event) {
            event.preventDefault();
            this.$modal.show(this.name);
        },
        getMachines: function () {
            let self = this;
            axios.get(this.$root.route('ajax.machines.index'), {
                params: {
                    'filter[service_destination]': (this.service_destination || {}).uuid,
                    'filter[service_origin]': (this.service_origin || {}).uuid,
                }
            })
            .then(function (response) {
                self.data = response.data.data;
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        select: function (machine) {
            this.selected = machine.name;
            this.machine = machine;
            this.$modal.hide(this.name);
        }
    },
    mounted () {
        if (!this.service && !(!this.service_origin && !this.service_destination))
            this.getMachines();
        if(this.selectedMachine) {
            this.selected = this.selectedMachine.name;
            this.machine = this.selectedMachine;
        }
    },
    watch: {
        selected: function () {
            this.$emit('input', this.machine);
        },
        service_origin: function (value) {
            if (!this.service && !(!this.service_origin && !this.service_destination))
                this.getMachines();
            else
                this.data = [];
        },
        service_destination: function (value) {
            if (!this.service && !(!this.service_origin && !this.service_destination))
                this.getMachines();
            else
                this.data = [];
        }
    }
}
</script>

<style>

</style>