var descriptions = {
    takenOut: 'takenOut',
    awaitingInsert: 'awaitingInsert',
    pickedUp: 'pickedUp',
    awaitingReception: 'awaitingReception',
    inMachine: 'inMachine',
    error: 'Something went wrong while loading the description',
};
   
export default descriptions
    
    
    