<template>
    <div class="" v-if="package">
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">{{ 'packages.package' | trans }}:{{ package.uuid }}</h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="javascript:void(0);" v-on:click="$router.push({name: 'dashboard'})">{{ 'app.home' | trans }}</a></li>
                            <li class="breadcrumb-item"><a href="javascript:void(0);" v-on:click="$router.push({name: 'packages-index'})">{{ 'packages.packages' | trans }}</a></li>
                            <li class="breadcrumb-item active">{{ 'packages.package' | trans }}</li>
                        </ol>
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <div class="content">
        <div class="row mb-4">
            <div :class="package.origin_address ? 'container col-3' : 'container col-4'">
                <div class="card p-4" style="flex-wrap: wrap; height: 100%;">
                    <h3>{{ 'payments.payment' | trans }}: </h3>
                    <span v-if="payment" v-for="payment in payments">
                        <p>{{ 'payments.amount' | trans }}: {{ payment.amount }}</p>
                        <p>{{ 'payments.status' | trans }}: <span :class="'badge ' + payment.status_name">{{ 'payments.' + payment.status_name | trans }}</span></p>
                        <p>{{ 'payments.type' | trans }}: {{ 'payments.' + payment.type_name | trans }}</p>
                        <p>UUID: {{ payment.uuid }}</p>
                        <hr v-if="payments.length > 1">
                    </span>
                    <p v-if="!payment"> {{ 'payment.noPayment' | trans }}</p>
                </div>
            </div>
            <div v-if="package.destination" :class="package.origin_address ? 'container col-3' : 'container col-4'">
                <div class="card p-4" style="flex-wrap: wrap; height: 100%;">      
                    <h3>{{ 'machines.destination' | trans }}: </h3>
                    <p>{{ 'machines.name' | trans }}: {{ package.destination.name }}</p>
                    <p>{{ 'machines.opening_hours' | trans }}: {{ package.destination.opening_hours }}</p>
                    <p>{{ 'machines.localization' | trans }}: {{ package.destination.city }}, {{ package.destination.street }}, {{ package.destination.building_number }}</p>
                </div>
            </div>
            <div v-if="package.destination_address" :class="package.origin_address ? 'container col-3' : 'container col-4'">
                <div class="card p-4" style="flex-wrap: wrap; height: 100%;">      
                    <h3>{{ 'packages.destination_address' | trans }}: </h3>
                    <p v-if="package.destination_address.province">{{ package.destination_address.country.name }}, {{ package.destination_address.province.name }}, {{ package.destination_address.city }}</p>
                    <p v-else>{{ package.destination_address.country.name }}, {{ package.destination_address.city }}</p>
                    <p>{{ package.destination_address.street }} {{ package.destination_address.building_number }}/{{ package.destination_address.apartment_number }}</p>
                </div>
            </div>
            <div v-if="package.origin_address" :class="package.origin_address ? 'container col-3' : 'container col-4'">
                <div class="card p-4" style="flex-wrap: wrap; height: 100%;">      
                    <h3>{{ 'packages.origin_address' | trans }}: </h3>
                    <p v-if="package.origin_address.province">{{ package.origin_address.country.name }}, {{ package.origin_address.province.name }}, {{ package.origin_address.city }}</p>
                    <p v-else>{{ package.origin_address.country.name }}, {{ package.origin_address.city }}</p>
                    <p>{{ package.origin_address.street }} {{ package.origin_address.building_number }}/{{ package.origin_address.apartment_number }}</p>
                </div>
            </div>
            <div :class="package.origin_address ? 'container col-3' : 'container col-4'">
                <div class="card p-4" style="flex-wrap: wrap; height: 100%;">
                    <h3>{{ 'packages.sender' | trans }}: </h3>
                    <p v-if="package.sender.name">{{ package.sender.name }}</p>
                    <p>{{ package.sender.phone }}</p>
                    <h3>{{ 'packages.recipient' | trans }}: </h3>
                    <p v-if="package.recipient.name">{{ package.recipient.name }}</p>
                    <p>{{ package.recipient.phone }}</p>
                </div>
            </div>
        </div>
            <search-component model="parcels" header="parcels" :params="{ 'filter[package_uuid]' : package.uuid }" :includeCollapse="includeCollapse">
                <template v-slot:labels>
                    <th>#</th>
                    <th>{{ 'parcels.name' | trans }}</th>
                    <th>{{ 'parcels.gauge' | trans }}</th>
                    <th>{{ 'parcels.weight' | trans }} (kg)</th>
                    <th>{{ 'parcels.status' | trans }}</th>
                </template>
                <template v-slot:columns="slotProps">
                    <td>{{ slotProps.element.uuid }}</td>
                    <td>{{ slotProps.element.name }}</td>
                    <td>{{ slotProps.element.gauge }}</td>
                    <td>{{ slotProps.element.weight }}</td>
                    <td><span :class="'badge ' + slotProps.element.status.name">{{ slotProps.element.status.name | trans}}</span></td>
                </template>
                <template v-slot:collapse="slotProps">
                    <div class="container pt-4 pb-0 px-4">
                        <div class="row">
                            <div class="col-3">
                                <h4>{{ 'parcels.statuses_history' | trans }}</h4>
                                <timeline-component :items="slotProps.element.status" @pickRow="changeDescription"/>
                            </div>
                            <div class="card-body p-4 col-9" v-if="statusDescription">
                                <p>{{ statusDescription.description }}</p>
                                <p>{{ statusDescription.created_at }}</p>                                 
                            </div>    
                        </div>
                    </div>
                </template>
            </search-component>
        </div>
    </div>
</template>

<script>
import descriptions from './status-descriptions.js';
import SearchComponent from '../../components/SearchComponent.vue';
import TimelineComponent from '../../components/TimelineComponent.vue';

export default {
    components: {
        SearchComponent,
        TimelineComponent,
    },
    data: function () {
        return {
            statusDescription: null,
            includeCollapse: true,
            package: null,
            payments: null,
        }
    },
    props: {
        uuid: null,
    },
    methods: {
         changeDescription: function(status) {
            self = this;
            this.statusDescription = null;
            var date = new Date(status.created_at );
            status.created_at = date.toLocaleString();

           if (status.name == "takenOut" || status.name == "emergencyTakenOut") {
                var date = new Date(status.created_at );
                status.created_at = date.toLocaleString();
                self.statusDescription = status;
                self.statusDescription.description = descriptions.takenOut;
            }
            else if (status.name == "awaitingInsert") {
                var date = new Date(status.created_at );
                status.created_at = date.toLocaleString();
                self.statusDescription = status;
                self.statusDescription.description =  descriptions.awaitingInsert;
            }
            else if (status.name == "pickedUp") {
                var date = new Date(status.created_at );
                status.created_at = date.toLocaleString();
                self.statusDescription = status;
                self.statusDescription.description = descriptions.pickedUp;
            }
            else if (status.name == "awaitingReception") {
                var date = new Date(status.created_at );
                status.created_at = date.toLocaleString();
                self.statusDescription = status;
                self.statusDescription.description = descriptions.awaitingReception;
            }
            else if (status.name == "inMachine") {
                var date = new Date(status.created_at );
                status.created_at = date.toLocaleString();
                self.statusDescription = status;
                self.statusDescription.description = descriptions.inMachine;
            }
            else {
                self.statusDescription.description = description.error;
            }
        },
        loadPackage () {
            axios.get(this.$root.route('ajax.packages.show', this.uuid))
            .then(response => this.package = response.data.data);
        },
        loadPayment () {
            axios.get(this.$root.route('ajax.payments.index'), {
                params: {
                    'filter[package_uuid]': this.uuid,
                }
            })
            .then(response => this.payments = response.data.data);   
        }
    },
    mounted () {
        this.loadPackage();
        this.loadPayment();
    }
}
</script>
<style lang="scss" scoped>
@import 'resources/sass/_payment-statuses.scss';
@import 'resources/sass/_parcel-statuses.scss';
</style>