<template>
    <div class="container">
        <div v-if="!entity" class="card text-center mt-4">
            <div class="card-body">
                <RingLoader :color="'#54f1d2'" />
            </div>
        </div>
        <div v-else class="card p-4 mt-3">
            <div class="card-body">
                <h3 class="mb-4">{{ 'app.balance' | trans }}: {{ entity.balance }}</h3>
                <button type="button" class="btn btn-primary" v-on:click="$router.push({ name: 'payments-create'})" block>{{ 'payments.add_balance' | trans }} <font-awesome-icon icon="fa-solid fa-plus"/></button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data: function() {
        return {
            loading: true,
            entity: null,
        }
    },
    methods: {
        loadData: function() {
            axios.get(this.$root.route('ajax.entities.index'), { 
            })
            .then((response) => {
                this.entity = response.data.data
            })
        }
    },
    mounted() {
        this.loadData();
    }
}
</script>