window._ = require('lodash');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
const axios = require('axios');

var pendingRequests = [];

/**
 * Create our own getDebounced method that will automatically cancel all pending requests
 * to the same endpoint when new one appears. And inject it to the axios prototype.
 */
axios.getDebounced = async function (url, params, componentId = '') {
    let cancelToken = axios.CancelToken.source();

    let urlKey = url + componentId;

    let request = axios.get(url, {
        ...params,
        cancelToken: cancelToken.token,
    });

    (pendingRequests[urlKey] || (pendingRequests[urlKey] = [])).push({
        'request': request,
        'cancelToken': cancelToken,
    });

    if(pendingRequests[urlKey].length >= 2) {
        pendingRequests[urlKey][pendingRequests[urlKey].length - 2].cancelToken.cancel();

        pendingRequests[urlKey].shift();
    }

    const onlyRequests = pendingRequests[urlKey].map(obj => obj.request);

    return Promise.allSettled(onlyRequests)
        .then((results) => {
            const fulfilledResults = results.filter(result => result.status === 'fulfilled');

            if(fulfilledResults.length == 0) {
                throw {
                    isCancelled: true
                };
            }

            // Extract the values of the fulfilled promise.
            const fulfilledValues = { ...fulfilledResults.map(result => result.value)[0] };

            pendingRequests[urlKey] = [];

            return fulfilledValues;
        })
}

axios.__proto__.getDebounced = axios.getDebounced;

window.axios = axios;

let isDebug = false;

axios.get('/admin/ajax/get-debug-state', {
    //
}).then(function (response) {
    isDebug = response.data.state;
}).catch(function (error) {
    //
});

import { error } from 'jquery';
import Swal from 'sweetalert2';
window.axios.interceptors.response.use(
    response => { return response },
    error => {
        if (error.response?.status == 404 && !isDebug) {       
            return document.location.href ='/404';
        }
        if (error.response?.status == 401) {
            return document.location.href = '/login';
        }
        if (error.response?.status == 403) {
            Swal.fire({
                icon: 'error',
                title: 'Błąd',
                text: 'Nie masz uprawnień do tego zasobu. Error: ' + error,
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '$3085d6',
                confirmButtonText: 'Zmień konto',
                focusCancel: true,
            }).then(result => {
                if (result.isConfirmed) {
                    axios.post('/logout')
                    .then( () => {
                        document.location.href = '/login';
                    });
                }
            });
        }

        throw error;
    }
);

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });

window.$ = window.jQuery = require('jquery');

require('overlayscrollbars');
require('../../vendor/almasaeed2010/adminlte/dist/js/adminlte');