<template>
    <div class="container">
        <div v-if="loading" class="card text-center mt-4">
            <div class="card-body">
                <RingLoader :color="'#54f1d2'" />
            </div>
        </div>
        <div v-else class="card text-bg-primary text-center p-4 mt-3">
            <div class="card-body">
                <div v-if="services.error">
                    <h3 class="mb-4">{{ 'app.top_services' | trans }}</h3>
                    <p>{{ 'app.non' | trans }}</p><button type="button" class="btn btn-success" :href="$root.route('services.index')">{{ 'services.services' | trans }}</button>
                </div>
                <div v-else>
                    <h3 class="mb-4">{{ 'app.top_services' | trans }}</h3>
                    <h4>1. {{ services[0].service.name }}: {{ services[0].packages_count }}</h4>
                    <h4 v-if="services[1]" >2. {{ services[1].service.name }}: {{ services[1].packages_count }}</h4>
                    <h4 v-if="services[2]" >3. {{ services[2].service.name }}: {{ services[2].packages_count }}</h4>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data: function() {
            return {
                services: null,
                loading: true,
            }
        },
        methods: {
            loadData: function() {
                axios.get(this.$root.route('ajax.services.top-services-component-data'))
                .then((response) => {
                    this.services = response.data;
                    this.loading = false;
                })
            }
        },
        mounted() {
            this.loadData();
        }
    }
</script>