<template>
    <div class="container">
        <div v-if="loading" class="card text-center mt-4">
            <div class="card-body">
                <RingLoader :color="'#54f1d2'" />
            </div>
        </div>
        <div v-else>
            <div v-if="chartData.datasets[0].data[0] || chartData.datasets[0].data[1]" class="card border-primary text-center p-4 mt-3">
                <div class="card-body">
                    <h3 class="mb-4">{{ 'app.package_amount' | trans }}</h3>
                    <pie-chart :data="chartData" :options="chartOptions"></pie-chart>
                </div>
            </div>
            <div v-else class="card border-primary text-center p-4 mt-3">
                <div class="card-body">
                    <h3 class="mb-4">{{ 'app.package_amount' | trans }}</h3>
                    <p>{{ 'app.last_package_no_package' | trans }}</p><button type="button" class="btn btn-success" :href="$root.route('packages.create')">{{ 'packages.create' | trans }}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PieChart from './PieChart.vue';
export default {
    components: {
        PieChart,
    },
    data: function() {
        return {
            loading: true,
            chartOptions: {
                hoverBorderWidth: 20
            },
            chartData: {
                hoverBackgroundColor: "red",
                hoverBorderWidth: 10,
                labels: [],
                datasets: [
                    {
                        label: "Data One",
                        backgroundColor: ["#41B883", "#E46651", "#00D8FF"],
                        data: [0, 0],
                    }
                ]
            }
        }
    },
    methods: {
        loadData: function() {
            axios.get(this.$root.route('ajax.packages.package-amount-component-data'), { 
            })
            .then((response) => {
                this.chartData.datasets[0].data = response.data;
                this.loading = false;
            })
        }
    },
    mounted() {
        this.loadData();
        this.chartData.labels.push(this.$options.filters.trans('packages.outgoing'));
        this.chartData.labels.push(this.$options.filters.trans('packages.incoming'));
    }
}
</script>