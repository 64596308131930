<script>
import { Bar } from 'vue-chartjs'

export default {
    extends: Bar,
    props: {
        labels: null,   
        dataSetArray: null,
        legendDisplay: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        makeDataSets: function() {
            
        }
    },
    mounted () {
        this.renderChart({
            labels: this.labels,
            datasets: this.dataSetArray,
        },
        {
            responsive: true,
            scales: {
                yAxes: [{
                    display: true,
                    ticks: {
                        beginAtZero: true,
                    }
                }]
            },
            animation: {
                duration: 0
            },
            hover: {
                animationDuration: 0
            },
            responsiveAnimationDuration: 0,
            legend: {
                display: this.legendDisplay
            }
        })
    }
}
</script>

<style>
</style>