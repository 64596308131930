<template>
    <div v-if="service" class="">
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">{{ 'services.service' | trans }}: {{ service.name }}</h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="javascript:void(0);" v-on:click="$router.push({name: 'dashboard'})">{{ 'app.home' | trans }}</a></li>
                            <li class="breadcrumb-item"><a href="javascript:void(0);" v-on:click="$router.push({name: 'services-index'})">{{ 'services.services' | trans }}</a></li>
                            <li class="breadcrumb-item active">{{ service.name }}</li>
                        </ol>
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <div class="content">
        <div class="row mb-4">
            <div class="container col-6">
                <div class="card p-4" style="flex-wrap: wrap; height: 100%;">
                    <h3>{{ 'services.details' | trans }}: </h3>
                    <!--First Mile-->
                    <h5 v-if="service.type == 1">
                        {{ 'services.type' | trans }}: {{ 'services.firstMileExplenation' | trans }} ({{ 'services.firstMile' | trans }})
                    </h5>
                    <!--Last Mile-->
                    <h5 v-if="service.type == 2">
                        {{ 'services.type' | trans }}: {{ 'services.lastMileExplenation' | trans }} ({{ 'services.lastMile' | trans }})
                    </h5>
                    <!--Storage-->
                    <h5 v-if="service.type == 3">
                        {{ 'services.type' | trans }}: {{ 'services.storageExplenation' | trans }} ({{ 'services.storage' | trans }})
                    </h5>
                    <!--End2End-->
                    <h5 v-if="service.type == 4">
                        {{ 'services.type' | trans }}: {{ 'services.endToEndExplenation' | trans }} ({{ 'services.endToEnd' | trans }})
                    </h5>
                    <p v-if="service.price > 0">{{ 'services.base_price' | trans }}: {{ service.price }}</p>
                    <p v-else>{{ 'services.base_price' | trans }}: <span class="badge bg-success">{{ 'services.no_base_price' | trans }}</span></p>
                    <p v-if="service.parcel_limit">{{ 'services.parcel_limit' | trans }}: {{ service.parcel_limit }}</p>
                    <p v-else>{{ 'services.parcel_limit' | trans }}: <span class="badge bg-success">{{ 'services.unlimited' | trans }}</span></p>
                    <p v-if="service.limit > 0">{{ 'services.use_limit' | trans }}: {{ service.limit }}</p>
                    <p v-else>{{ 'services.use_limit' | trans }}: <span class="badge bg-success">{{ 'services.unlimited' | trans }}</span></p>
                    <p v-if="service.limit > 0">{{ 'services.used' | trans }}: {{ service.used }}</p>
                    <p v-else>{{ 'services.used' | trans }}: <span class="badge bg-success">{{ 'services.unlimited' | trans }}</span></p>
                    <p v-if="service.valid_until">{{ 'services.valid_until' }}: {{ service.valid_until }}</p>
                    <p v-else>{{ 'services.valid_until' | trans}}: <span class="badge bg-success">{{ 'services.unlimited' | trans }}</span></p>
                </div> 
            </div>
            <div class="container col-6">
                <div class="card p-4" style="flex-wrap: wrap; height: 100%;">      
                   <h3>{{ 'services.description' | trans }}: </h3>
                   <p v-if="service.description">{{ service.description }}</p>
                   <p v-else>{{ 'services.no_description' | trans }}</p>
                </div>
            </div>
            
        </div>
            <search-component model="gauges" header="gauges.gauges" :params="{ 'filter[service_uuid]' : service.uuid }">
                <template v-slot:labels>
                    <th>{{ 'gauges.name' | trans }}</th>
                    <th>{{ 'gauges.price' | trans }}</th>
                    <th>{{ 'gauges.max_weight' | trans }}</th>
                    <th>{{ 'gauges.dimensions' | trans }}</th>
                </template>
                <template v-slot:columns="slotProps">
                    <td>{{ slotProps.element.name }}</td>
                    <td v-if="slotProps.element.price != 0">{{ slotProps.element.price }}</td>
                    <td v-else><span class="badge bg-success">{{ 'services.no_price' | trans }}</span></td>
                    <td>{{ slotProps.element.max_weight }} kg</td>
                    <td>{{ slotProps.element.depth }}, {{ slotProps.element.width }}, {{ slotProps.element.height }}</td>
                </template>
            </search-component>
        </div>
    </div>
</template>
<script>
import SearchComponent from '../../components/SearchComponent.vue';

export default {
    components: {
        SearchComponent,
    },
    data: function () {
        return {
           service: null,
        }
    },
    props: {
        uuid: null,
    },
    methods: {
        loadService: function() {
            let self = this;
            axios.get(this.$root.route('ajax.services.show', this.uuid))
            .then(function (response) {
                self.service = response.data.data;
            }) 
        },
    },
    mounted() {
        this.loadService();
    }
  
}  
</script>