<template>
    <div>
        <navbar-component-template />
        <div class="content-wrapper">
            <div class="container">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import NavbarComponentTemplate from '../components/navbar/NavbarComponentTemplate.vue';
export default {
    components: {
        NavbarComponentTemplate,
    }

}
</script>

<style>

</style>