<template>
     <div class="container mt-4">
     <h4>Payment UUID: {{ uuid }}</h4>
        <div v-if="status != 5">
            <div class="content d-flex justify-content-center mt-4">
                <div class="contrainer">
                    <DualRingLoader :size="200" :color="'#54f1d2'" />
                </div>
            </div>
            <div class="content container d-flex justify-content-center mt-4">
                <div class="container">
                    <h1 class="text-center">Proccessing Payment...</h1>
                </div>
            </div>
        </div>
        <div v-else>
             <div class="content d-flex justify-content-center mt-4">
                <div class="contrainer mt-4">
                <span style="color: green;">
                    <font-awesome-icon icon="fa-solid fa-check-circle fa-10x"/>
                </span>
                </div>
            </div>
            <div class="content container d-flex justify-content-center mt-4">
                <div class="container mt-4">
                    <h1 class="text-center">Payment Completed</h1>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { DualRingLoader } from 'vue-spinners-css';

export default {
    data: function () {
        return {
            payment: null,
            status: 1,
        }
    },
    components: {
        DualRingLoader,
    },
    props: {
        uuid: null,
    },
    methods: {
        loadPayment: function() {

            self = this;
            axios.get(this.$root.route('ajax.payments.show', this.uuid))
            .then(function (response) {
                self.payment = response.data.data;
                if (self.payment.status != 5) {
                    setTimeout(() => self.loadPayment(), 5000);
                }
                else if (self.payment.status == 5) {
                    self.status = 5; 
                }
            })
        }
    },
    mounted() {
        this.loadPayment();
    }
}
</script>