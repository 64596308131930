<template>
    <div v-if="entity">
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">{{ 'entities.settings' | trans }}</h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="javascript:void(0);" v-on:click="$router.push({name: 'dashboard'})">{{ 'app.home' | trans }}</a></li>
                            <li class="breadcrumb-item active">{{ 'entities.entity' | trans }}</li>
                        </ol>
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!--Index page-->
        <div v-if="!page" class="content">
            <div class="row d-flex mb-2 mt-2">
                <div class="container col-sm-12 col-md-6 mb-4">
                    <div class="card overflow-hidden p-4 m-4 h-100">
                        <div class="card-body d-flex flex-column">
                            <h2 class="mb-4">{{ 'entities.entity' | trans }}</h2>
                            <h4>{{ 'entities.name' | trans }}: {{ entity.name }}</h4>
                            <h4>{{ 'entities.phone' | trans }}: {{ entity.entity_address.phone }} <font-awesome-icon v-if="!entity.entity_address.phone_verified_at" v-on:click="showModalEntityPhone()" style="color: orange" icon="fa-solid fa-exclamation-triangle"/></h4>
                            <h4>{{ 'entities.email' | trans }}: {{ entity.entity_address.email }} <font-awesome-icon v-if="!entity.entity_address.email_verified_at" v-on:click="showModalEntityEmail()" style="color: orange" icon="fa-solid fa-exclamation-triangle"/></h4>
                            <h4 v-if="entity.country">{{ 'entities.address' | trans }}: {{ entity.country.name }} <span v-if="!entity.country" class="badge bg-danger">{{ 'app.non' | trans }}</span>, <span v-if="!entity.province" class="badge bg-danger">{{ 'app.non' | trans }}</span><template v-else>{{ entity.province.name }}</template>, {{ entity.city }}, {{ entity.street }}, {{ entity.building_number }}<template v-if="entity.apartment_number">/</template>{{ entity.apartment_number }}, {{ entity.zip_code }}</h4>
                            <h4 v-if="entity.nip_number">{{ 'entities.nip_number' | trans }}: {{ entity.nip_number }}</h4>
                            <h4 v-if="entity.krs_number">{{ 'entities.krs_number' | trans }}: {{ entity.krs_number }}</h4>
                            <h4 v-if="entity.regon_number">{{ 'entities.regon_number' | trans }}: {{ entity.regon_number }}</h4>
                            <h4 v-if="entity.vat_number">{{ 'entities.vat_number' | trans }}: {{ entity.vat_number }}</h4>
                            <p style="color: orange" v-if="!entity.entity_address.email_verified_at || !entity.entity_address.phone_verified_at">{{'entities.validation_required' | trans}}</p>
                            <button type="button" class="btn btn-outline-success mt-auto" v-on:click="page = 1" href="javascript:void(0);">{{ 'entities.edit' | trans }}</button>
                        </div>
                    </div>
                </div>
                <div class="container col-sm-12 col-md-6 mb-4">
                    <div class="card overflow-hidden p-4 m-4 h-100">
                        <div class="card-body d-flex flex-column">
                            <h2 class="mb-4">{{ 'entities.user' | trans }}</h2>
                            <h4>{{ 'entities.name' | trans }}: {{ user.name }}</h4>
                            <h4>{{ 'entities.phone' | trans }}: {{ user.phone }}</h4>
                            <h4>{{ 'entities.email' | trans }}: {{ user.email }}</h4>
                            <button type="button" class="btn btn-outline-success mt-auto" v-on:click="page = 2" href="javascript:void(0);">{{ 'entities.edit' | trans }}</button>
                        </div>
                    </div>
                </div>
            </div><!-- /.row -->
        </div>
        <!--Entity Page-->
        <div v-if="page == 1" class="content">
            <div class="container card-body">
                <div class="mb-3">
                    <label for="emailInput">{{ 'entities.email' | trans }}</label>
                    <input v-model="entity.email" class="form-control text-start" id="emailInput" :placeholder="'entities.email' | trans">

                    <label for="nameInput">{{ 'entities.name' | trans }}</label>
                    <input v-model="entity.name" class="form-control text-start" id="nameInput" :placeholder="'entities.name' | trans">

                    <label>{{ 'entities.phone' | trans }}</label>
                    <vue-tel-input @input="onInputEntity" v-model="entity.phone"></vue-tel-input>

                    <label for="country_idInput">{{ 'entities.country_id' | trans }}</label>
                    <country-picker-component v-model="entity.country"></country-picker-component>

                    <label for="province_idInput">{{ 'entities.province_id' | trans }}</label>
                    <province-picker-component v-if="!entity.country" :disabled="true" v-model="entity.province"></province-picker-component>
                    <province-picker-component v-else :disabled="false" :country_id="entity.country.id" v-model="entity.province"></province-picker-component>
                    
                    <label for="cityInput">{{ 'entities.city' | trans }}</label>
                    <input v-model="entity.city" class="form-control text-start" id="cityInput" :placeholder="'entities.city' | trans">
                    
                    <label for="streetInput">{{ 'entities.street' | trans }}</label>
                    <input v-model="entity.street" class="form-control text-start" id="streetInput" :placeholder="'entities.street' | trans">
                    
                    <label for="building_numberInput">{{ 'entities.building_number' | trans }}</label>
                    <input v-model="entity.building_number" class="form-control text-start" id="building_numberInput" :placeholder="'entities.building_number' | trans">
                    
                    <label for="apartment_numberInput">{{ 'entities.apartment_number' | trans }}</label>
                    <input v-model="entity.apartment_number" class="form-control center-block text-start" id="apartment_numberInput" :placeholder="'entities.apartment_number' | trans">
                    
                    <label for="zip_codeInput">{{ 'entities.zip_code' | trans }}</label>
                    <input v-model="entity.zip_code" class="form-control center-block text-start" id="zip_codeInput" :placeholder="'entities.zip_code' | trans">

                </div>
                <div class="row mt-4">
                    <div class="col-4 d-flex">
                        <button type="button" class="btn btn-outline-primary ms-auto" v-on:click="page = 0" variant="outline-primary">{{ 'app.back' | trans }}</button>
                    </div>
                    <div class="col-4 d-flex">
                    </div>
                    <div class="col-4 d-flex">
                        <button type="button" class="btn btn-outline-primary" v-on:click="updateEntity" :disabled="!(entityValid && entityEmailValid && entityPhoneValid)">{{ 'app.confirm' | trans }}</button> <!--Checking if every value is at least filled-->
                    </div>       
                </div>
		    </div> 
        </div>
        <!--User Page-->
        <div v-if="page == 2" class="content">
            <div class="container card-body">
                <div class="mb-3">
                    <label for="nameInput">{{ 'entities.name' | trans }}</label>
                    <input v-model="user.name" class="form-control text-start" id="nameInput" :placeholder="'entities.name' | trans">

                    <h4 class="mt-4">{{ 'entities.password' | trans }}</h4>
                    <label>{{ 'entities.old_password' | trans }}</label>
                    <input type="password" v-model="oldPassword" class="form-control text-start" id="oldPasswordInput" :placeholder="'entities.old_password' | trans">

                    <label>{{ 'entities.new_password' | trans }}</label>
                    <input type="password" v-model="newPassword" class="form-control text-start" id="newPasswordInput" :placeholder="'entities.new_password' | trans">
                </div>
                <div class="row mt-4">
                    <div class="col-4 d-flex">
                        <button type="button" class="btn btn-outline-primary ms-auto" v-on:click="page = 0">{{ 'app.back' | trans }}</button>
                    </div>
                    <div class="col-4 d-flex">
                    </div>
                    <div class="col-4 d-flex">
                        <button type="button" class="btn btn-outline-primary" v-on:click="updateUser" :disabled="!(user.name.length > 0)">{{ 'app.confirm' | trans }}</button> <!--Checking if every value is at least filled-->
                    </div>
                </div>
		    </div>
        </div>

        <div class="modal" :id="uid + 'modalEntityEmail'">
			<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
    			<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="staticBackdropLabel">{{ 'entities.validate_email' | trans }}</h5>
					</div>

					<div class="modal-body">
                        <input type="text" class="form-control mb-4" v-model="entityEmailCode" :placeholder="'entities.enter_code' | trans">

                        <div class="container">
                            <div class="row d-flex">
                                <button type="button" class="btn btn-warning" v-on:click="initEntityEmailVerification">{{'entities.resend' | trans}}</button>
                                <button type="button" class="btn btn-success ms-auto" v-on:click="checkEmailCode">{{'entities.validate' | trans}}</button>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger btn-sm" @click="cancel()">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" :id="uid + 'modalEntityPhone'">
			<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
    			<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="staticBackdropLabel">{{ 'entities.validate_phone' | trans }}</h5>
					</div>

					<div class="modal-body">
                        <input type="text" class="form-control mb-4" v-model="entityPhoneCode" :placeholder="'entities.enter_code' | trans">

                        <div class="container">
                            <div class="row d-flex">
                                <button type="button" class="btn btn-warning" v-on:click="initEntityPhoneVerification">{{'entities.resend' | trans}}</button>
                                <button type="button" class="btn btn-success ms-auto" v-on:click="checkPhoneCode">{{'entities.validate' | trans}}</button>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-danger btn-sm" @click="cancel()">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
 
<script>
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import CountryPickerComponent from '../../pickers/CountryPickerComponent';
import ProvincePickerComponent from '../../pickers/ProvincePickerComponent';

import { Modal } from 'bootstrap';

export default {
    components: {
        VueTelInput,
        CountryPickerComponent,
        ProvincePickerComponent,
    },
    data: function () {
        return {
            entity: null,
            user: null,
            nonLang: null,
            page: 0,
            type: null,
            entityPhone: null,
            entityPhoneValid: false,
            test: /^[^\s@]+@[^\s@]+$/,
            oldPassword: null,
            newPassword: null,
            entityEmailCode: null,
            entityPhoneCode: null,

            modalEntityEmail: null,
            modalEntityPhone: null,

            uid: null,
        }
    },
    props: {
        
    },
    methods: {
        showModalEntityEmail: function () {
            this.modalEntityEmail = new Modal(document.getElementById(this.uid + 'modalEntityEmail'))
            this.modalEntityEmail.show();
        },
        hideModalEntityEmail: function () {
            this.modalEntityEmail.hide();
        },
        showModalEntityPhone: function () {
            this.modalEntityPhone = new Modal(document.getElementById(this.uid + 'modalEntityPhone'))
            this.modalEntityPhone.show();
        },
        hideModalEntityPhone: function () {
            this.modalEntityPhone.hide();
        },


        initEntityEmailVerification: function() {
            let self= this;
            axios.get(this.$root.route('ajax.entities.validate-email'), {params: {
                email: self.entity.entity_address.email,
            }})
            .then(function (response) {
                console.log(response.data);
            })
        },
        initEntityPhoneVerification: function() {
            let self= this;
            axios.get(this.$root.route('ajax.entities.validate-phone'), {params: {
                phone: self.entity.entity_address.phone,
            }})
            .then(function (response) {
                console.log(response.data);
            })
        },
        checkEmailCode: function() {
            let self= this;
            axios.post(this.$root.route('ajax.entities.callback-email'), {
                email: self.entity.entity_address.email,
                code: self.entityEmailCode,
            })
            .then(function (response) {
                Swal.fire({
                    icon: 'success',
                    title: 'Email Verified',
                })
                self.showModalEntityEmail();
                self.loadEntity();
            }) 
            .catch(error =>{  //fail
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.error,
                })
            })
        },
        checkPhoneCode: function() {
            let self= this;
            axios.post(this.$root.route('ajax.entities.callback-phone'), {
                phone: self.entity.entity_address.phone,
                code: self.entityPhoneCode,
            })
            .then(function (response) {
                Swal.fire({
                    icon: 'success',
                    title: 'Phone Verified',
                })
                self.hideModalEntityPhone();
                self.loadEntity();
            }) 
            .catch(error =>{  //fail
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.error,
                })
            })  
        },
        loadEntity: function() {
            let self= this;
            axios.get(this.$root.route('ajax.entities.index'))
            .then(function (response) {
                self.entity = response.data.data;
                if (self.entity.nip_number)
                    self.type = true; // this is business entity
                else
                    self.type = false; // this is personal entity
            })
        },
        loadUser: function() {
            let self= this;
            axios.get(this.$root.route('ajax.users.index'))
            .then(function (response) {
                self.user = response.data.data;
            })
        },
        onInputEntity: function(formattedNumber, phoneObject) {
            this.entityPhoneValid = false;
            this.entityPhone = phoneObject.number;
            if (phoneObject.valid == true) this.entityPhoneValid = true;
        },
        updateEntity: function() {
            let province = (this.entity.province ? this.entity.province.id : null);
            axios.put(this.$root.route('ajax.entities.update', this.entity.uuid), {
                email: this.entity.email,
                phone: this.entityPhone,
                name: this.entity.name,
                country_id: this.entity.country.id,
                province_id: province,
                city: this.entity.city,
                street: this.entity.street,
                building_number: this.entity.building_number,
                apartment_number: this.entity.apartment_number,
                zip_code: this.entity.zip_code,
                code: this.entityCode,
            })
			.then((response) => {
                Swal.fire({
                    icon: 'success',
                    title: '',
                })
                this.page = 0;
                this.loadEntity();
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.error,
                })
            })    
        },
        updateUser: function() {
            axios.put(this.$root.route('ajax.users.update', this.user.uuid), {
                name: this.user.name,
                oldPassword: this.oldPassword,
                newPassword: this.newPassword,
                code: this.userCode,
            })
			.then((response) => {
                Swal.fire({
                    icon: 'success',
                    title: '',
                })
                this.page = 0;
                this.loadEntity();
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.error,
                })
            })    
        }
    },
    computed: {
        entityEmailValid() {
            return this.test.test(this.entity.email);
            },
        entityValid() {
            if (this.type)
                if (this.entity.name && 
                    this.entity.city && 
                    this.entity.street && 
                    this.entity.building_number && 
                    this.entity.zip_code && 
                    this.entity.country)
                    return true;
                else 
                    return false;
            else 
                if (this.entity.name && 
                    this.entity.city && 
                    this.entity.street && 
                    this.entity.building_number && 
                    this.entity.zip_code && 
                    this.entity.country)
                    return true;
                else
                    return false;
        }
    },
    watch: {
        
    },
    created () {
        
    },
    mounted () {
        this.uid = this.$uuid.v4();

        this.loadEntity();
        this.loadUser();
        this.nonLang = this.$options.filters.trans('app.non');
    },
}
</script>

