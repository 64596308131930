<template>
    <div class="">
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">{{ 'packages.packages' | trans }}</h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="javascript:void(0);" v-on:click="$router.push({name: 'dashboard'})">{{ 'app.home' | trans }}</a></li>
                            <li class="breadcrumb-item active">{{ 'packages.packages' | trans }}</li>
                        </ol>
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <div class="content">
            <div class="row" v-if="generateChart">
                <div class="container col-6">
                    <div class="card">
                        <chart-component :chartColor="chartColor" :dataSetArray="dataSetArray1" :labels="labels"/>
                    </div>
                </div>
            <div class="container col-6">
                    <div class="card">
                        <chart-component :chartColor="chartColor" :dataSetArray="dataSetArray2" :labels="labels"/>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header">

                    <ul class="nav nav-tabs card-header-tabs" data-bs-tabs="tabs">
                        <li class="nav-item">
                            <a class="nav-link active" aria-current="true" data-bs-toggle="tab" href="#sent">{{ 'packages.sent' | trans }}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="tab" href="#reception">{{ 'packages.receptions' | trans }}</a>
                        </li>
                    </ul>

                    <div class="card-body tab-content">
                        <div class="tab-pane active" id="sent">
                            <p class="card-text">
                                <search-component ref="searchComponentIncoming" model="packages" header="packages.sent" :params="{ outgoing : true }" :includeCollapse="true" :includeFilters="true">
                                    <template v-slot:searchCollapse="slotProps">
                                        <div class="mt-4 d-flex justify-content-start">
                                            <div class="me-2 text-center">
                                                <label>{{ 'packages.' + filters[0].label | trans }}</label>
                                                <input v-model="filters[0].value" type="text" class="form-control">      
                                            </div>
                                            <div class="me-4 d-flex flex-column justify-content-end">
                                                <label class="text-center">{{ 'packages.statuses' | trans }}</label>

                                                <div v-if="statusesforSearch" class="dropdown">
                                                    <button class="btn btn-primary dropdown-toggle" type="button" id="statusDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                                        {{ 'packages' + '.' + pickedStatus.name | trans }}
                                                    </button>
                                                    <ul class="dropdown-menu" aria-labelledby="statusDropdown">
                                                        <li v-for="status in statusesforSearch" :key="status.name">
                                                            <a class="dropdown-item" href="javascript:void(0);" @click="pickedStatus = status">{{ 'packages' + '.' + status.name | trans }}</a>
                                                        </li>
                                                        <li>
                                                            <a class="dropdown-item" href="javascript:void(0);" @click="pickedStatus = { name: 'choose', ids: null }">{{ 'app.reset' | trans }}</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="d-flex flex-column justify-content-end">
                                                <div>
                                                    <button type="button" class="btn btn-warning" v-on:click="resetFilters">{{ 'app.filters_reset' | trans }}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-slot:labels>
                                        <th style="width: 15rem">#</th>
                                        <th>{{ 'packages.recipient' | trans }}</th>
                                        <th>{{ 'services.service' | trans }}</th>
                                        <th>{{ 'packages.destination' | trans }}</th>
                                        <th>{{ 'packages.parcels' | trans }}</th>
                                        <th>{{ 'packages.status' | trans }}</th>
                                    </template>
                                    <template v-slot:columns="slotProps">
                                        <td v-on:click="loadParcels(slotProps.element.uuid)">{{ slotProps.element.uuid }}</td>
                                        <td v-on:click="loadParcels(slotProps.element.uuid)">{{ slotProps.element.recipient.phone }}</td>
                                        <td v-on:click="loadParcels(slotProps.element.uuid)">{{ slotProps.element.service.name }}</td>
                                        <td v-if="slotProps.element.destination" v-on:click="loadParcels(slotProps.element.uuid)">
                                            <b>{{ 'machines.machine' | trans }} {{ slotProps.element.destination.name }}</b><br>
                                            <small> 
                                                {{ slotProps.element.destination.street }} 
                                                {{ slotProps.element.destination.building_number }} 
                                                {{ slotProps.element.destination.apartment_number }},
                                                {{ slotProps.element.destination.city }} 
                                                {{ slotProps.element.destination.zip_code }} 
                                            </small>
                                        </td>
                                        <td v-else v-on:click="loadParcels(slotProps.element.uuid)">
                                            Check details 
                                        </td>
                                        <td v-on:click="loadParcels(slotProps.element.uuid)">
                                            <span class="badge bg-info">{{ slotProps.element.parcels }}</span>
                                        </td>
                                        <td v-on:click="loadParcels(slotProps.element.uuid)"><span :class="'badge ' + slotProps.element.status_name">{{ 'packages.' + slotProps.element.status_name | trans }}</span></td>
                                    </template>
                                    <template v-slot:collapse="slotProps">
                                        <div v-if="!loading" class="container pt-4 pb-0 px-4">
                                            <div class="row">
                                                <div class="col-3">
                                                <div v-if="parcels && packageStatusesSame">
                                                    <h4>{{ 'packages.timeline' | trans }}</h4>
                                                    <timeline-component :items="parcels[0].status" @pickRow="changeDescription"/>
                                                </div>
                                                <div v-else-if="!parcels">
                                                    <h4>{{ 'parcels.no_statuses' | trans }}</h4>
                                                </div>
                                                <div v-else-if="parcels && !packageStatusesSame">
                                                    <h4>{{ 'parcels.many_statuses' | trans }}</h4>
                                                </div>
                                                </div>
                                                <div class="card-body p-4 col-9 mt-4" v-if="statusDescription">
                                                    <p>{{ statusDescription.description }}</p>
                                                    <p>{{ statusDescription.created_at.toLocaleString() }}</p>                                 
                                                </div>    
                                            </div>
                                            <div class="container w-50">
                                                <button type="button" class="btn btn-outline-primary" block><router-link :to="'/packages/' + slotProps.element.uuid">{{ 'packages.details' | trans }}</router-link></button>
                                            </div>
                                        </div>
                                        <div class="text-center mt-4" v-else>
                                            <RingLoader :color="'#54f1d2'" />
                                        </div>
                                    </template>
                                </search-component>
                            </p>
                        </div>
                        <div class="tab-pane" id="reception">
                            <p class="card-text">
                                <search-component ref="searchComponentOutgoing" model="packages" header="packages.receptions" :params="{ incoming : true }" :includeCollapse="true" :includeFilters="true">
                                    <template v-slot:searchCollapse="slotProps">
                                        <div class="mt-4 d-flex justify-content-start">
                                            <div class="me-2 text-center">
                                                <label>{{ 'packages.' + filters[0].label | trans }}</label>
                                                <input v-model="filters[0].value" type="text" class="form-control">      
                                            </div>
                                            <div class="me-4 d-flex flex-column justify-content-end">
                                                <label class="text-center">{{ 'packages.statuses' | trans }}</label>
                                                <div class="dropdown">
                                                    <button class="btn btn-primary dropdown-toggle" type="button" id="statusDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                                        {{ 'packages' + '.' + pickedStatus.name }}
                                                    </button>
                                                    <ul class="dropdown-menu" aria-labelledby="statusDropdown">
                                                        <li v-for="status in statusesforSearch" :key="status.name">
                                                            <a class="dropdown-item" href="javascript:void(0);" onclick="pickedStatus = status">{{ 'packages' + '.' + status.name }}</a>
                                                        </li>
                                                        <li>
                                                            <a class="dropdown-item" href="javascript:void(0);" onclick="pickedStatus = { name: 'choose', ids: null }">{{ 'app.reset' }}</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="d-flex flex-column justify-content-end">
                                                <div>
                                                    <button type="button" class="btn btn-warning" v-on:click="resetFilters">{{ 'app.filters_reset' | trans }}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-slot:labels>
                                        <th style="width: 15rem">#</th>
                                        <th>{{ 'packages.recipient' | trans }}</th>
                                        <th>{{ 'services.service' | trans }}</th>
                                        <th>{{ 'packages.destination' | trans }}</th>
                                        <th>{{ 'packages.parcels' | trans }}</th>
                                        <th>{{ 'packages.status' | trans }}</th>
                                    </template>
                                    <template v-slot:columns="slotProps">
                                        <td v-on:click="loadParcels(slotProps.element.uuid)">{{ slotProps.element.uuid }}</td>
                                        <td v-on:click="loadParcels(slotProps.element.uuid)">{{ slotProps.element.recipient.phone }}</td>
                                        <td v-on:click="loadParcels(slotProps.element.uuid)">{{ slotProps.element.service.name }}</td>
                                        <td v-if="slotProps.element.destination" v-on:click="loadParcels(slotProps.element.uuid)">
                                            <b>{{ 'machines.machine' | trans }} {{ slotProps.element.destination.name }}</b><br>
                                            <small> 
                                                {{ slotProps.element.destination.street }} 
                                                {{ slotProps.element.destination.building_number }} 
                                                {{ slotProps.element.destination.apartment_number }},
                                                {{ slotProps.element.destination.city }} 
                                                {{ slotProps.element.destination.zip_code }} 
                                            </small>
                                        </td>
                                        <td v-else v-on:click="loadParcels(slotProps.element.uuid)">
                                            Check details
                                        </td>
                                        <td v-on:click="loadParcels(slotProps.element.uuid)">
                                            <span class="badge bg-info">{{ slotProps.element.parcels }}</span>
                                        </td>
                                        <td v-on:click="loadParcels(slotProps.element.uuid)"><span :class="'badge ' + slotProps.element.status_name">{{ 'packages.' + slotProps.element.status_name | trans }}</span></td>
                                    </template>
                                    <template v-slot:collapse="slotProps">
                                        <div v-if="!loading" class="container pt-4 pb-0 px-4">
                                            <div class="row">
                                                <div class="col-3">
                                                <div v-if="parcels && packageStatusesSame">
                                                    <h4>{{ 'packages.timeline' | trans }}</h4>
                                                    <timeline-component :items="parcels[0].status" @pickRow="changeDescription" />
                                                </div>
                                                <div v-else-if="!parcels">
                                                    <h4>{{ 'parcels.no_statuses' | trans }}</h4>
                                                </div>
                                                <div v-else-if="parcels && !packageStatusesSame">
                                                    <h4>{{ 'parcels.many_statuses' | trans }}</h4>
                                                </div>
                                                </div>
                                                <div class="card-body p-4 col-9 mt-4" v-if="statusDescription">
                                                    <p>{{ statusDescription.description }}</p>
                                                    <p>{{ statusDescription.created_at.toLocaleString() }}</p>                                 
                                                </div>    
                                            </div>
                                            <div class="container w-50">
                                                <button type="button" class="btn btn-outline-primary" block><router-link :to="'/packages/' + slotProps.element.uuid">{{ 'packages.details' | trans }}</router-link></button>
                                            </div>
                                        </div>
                                        <div class="text-center mt-4" v-else>
                                            <RingLoader :color="'#54f1d2'" />
                                        </div>
                                    </template>
                                </search-component>
                            </p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import descriptions from './status-descriptions.js'
import SearchComponent from '../../components/SearchComponent.vue';
import ChartComponent from '../../components/ChartComponent.vue';
import TimelineComponent from '../../components/TimelineComponent.vue';

export default {
    components: {
        SearchComponent,
        ChartComponent,
        TimelineComponent,
    },
    data: function () {
        return {
            parcels: null,
            statusDescription: null,
            packageStatusesSame: true,
            graphData: null,
            labels: [],
            data: [
                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            ],
            dataSetArray1: [],
            dataSetArray2: [],
            generateChart: false,
            chartColor: '#79b9f8',
            loading: true,
            currentMonth: null,
            pickedStatus: { name: 'choose', ids: null }, 
            filters: [
                {
                    name: 'filter[search]',
                    value: null,
                    label: 'search', 
                },
            ],
            statusesforSearch: [
                {
                    name: 'DELIVERING',
                    ids: "1",
                },
                {
                    name: 'AWAITING_RECEPTION',
                    ids: "2",
                },
                {
                    name: 'PICKED_UP',
                    ids: "4",
                },
                {
                    name: 'TAKEN_OUT',
                    ids: "6",
                },
                {
                    name: 'MIXED_STATUS',
                    ids: "3,5,7,8"
                },
            ]
        }
    },
    props: {

    },
    methods: {
        loadParcels: function(packageUuid) {
            this.parcels = null;
            this.loading = true;
            let self = this;
            axios.get(this.$root.route('ajax.parcels.index'), {
                    params: {
                        'filter[package_uuid]': packageUuid,
                    }
                 })
                .then(function (response) {
                    self.parcels = response.data.data;
                    self.statusDescription = null;
                    if(self.parcels[0] == null) self.parcels = null;
                    self.combinedStatusForPackage();
                }) 
        },
        loadGraphData: function() {
            let self = this;
            axios.get(this.$root.route('ajax.packages.graph-data'))
            .then(function (response) {
                self.graphData = response.data;
                self.parseDataForGraph();
            })
        },
        changeDescription: function(status) {
            self = this;
            var date = new Date(status.created_at );
            status.created_at = date.toLocaleString();
            this.statusDescription = null;

            if (status.name == "takenOut" || status.name == "emergencyTakenOut") {
                self.statusDescription = status;
                self.statusDescription.description = descriptions.takenOut;
            }
            else if (status.name == "awaitingInsert") {
                self.statusDescription = status;
                self.statusDescription.description =  descriptions.awaitingInsert;
            }
            else if (status.name == "pickedUp") {
                self.statusDescription = status;
                self.statusDescription.description = descriptions.pickedUp;
            }
            else if (status.name == "awaitingReception") {
                self.statusDescription = status;
                self.statusDescription.description = descriptions.awaitingReception;
            }
            else if (status.name == "inMachine") {
                self.statusDescription = status;
                self.statusDescription.description = descriptions.inMachine;
            }
            else {
                self.statusDescription.description = description.error;
            }
        },
        combinedStatusForPackage: function() { //we check if all parcel's statuses for every parcel in a particular package match eachother with the date and neme if not we ask user to check details for more info
            this.packageStatusesSame = true;
            let self = this;
            if (self.parcels) {
                if (self.parcels.length > 1) { // check if every parcel has same amount of statuses if there is more then 1 parcel
                    for (var i = 0; i < self.parcels.length - 1; i++) {
                        if (self.parcels[i].status.length != self.parcels[i+1].status.length) {
                            self.packageStatusesSame = false;
                            self.loading = false;
                            return false;
                        }
                    }
                }

                else { // if there is 1, nothing can go wrong
                    self.loading = false;
                    return true;
                }
                
                this.parcels.reduce(function(a, e) {
                    var sorted = e.status.sort(function( a, b ) {
                        if ( a.name < b.name ){
                            return -1;
                        }
                        if ( a.name > b.name ){
                            return 1;
                        }
                        return 0;
                    })
                    if (!a) {
                        return sorted;
                    }
                    else {
                        let b = a;
                        if (sorted.find(function(element, index) {
                            if (b[index].name != element.name) {
                                return true;
                            }
                            let tmpDate = new Date(element.created_at);
                            let tmpBoundry = new Date (b[index].created_at);
                            if (tmpDate < tmpBoundry.setMinutes(tmpBoundry.getMinutes() - 15) ||
                                tmpDate > tmpBoundry.setMinutes(tmpBoundry.getMinutes() + 30)) {
                                return true;
                            }
                        })) 
                            self.packageStatusesSame = false;
                    }
                    self.loading = false;
                }, null)
            }
            self.loading = false;
        },
        parseDataForGraph: function() {
            for (var i = 0; i < this.data.length; i++) {
                for (var j = 0; j < this.graphData[i].length; j++){
                    // rearrange the values, the value for the current month is the last
                    this.data[i][this.$root.changeMonthToRelative(parseInt(this.graphData[i][j].date_part - 1) + this.labels.length - this.currentMonth - 1)] = this.graphData[i][j].packages_count;
                }
            }
            this.dataSetArray1.push({
                label: [this.$options.filters.trans('packages.sent')],
                backgroundColor: this.chartColor,
                data: this.data[0],
            })
            this.dataSetArray2.push({
                label: [this.$options.filters.trans('packages.receptions')],
                backgroundColor: this.chartColor,
                data: this.data[1],
            })
            this.generateChart = true;
        },
        fillMonthsArray: function() {
            this.labels = this.$root.getMonthsArray();
            
            var tmpLabels = [];
            // we rearrange the array, current month is last
            console.log('length' + this.labels.length)
            for (let i = 0; i < this.labels.length; i++) {
                tmpLabels[this.$root.changeMonthToRelative(i + this.labels.length - this.currentMonth -1)] = this.labels[i];
            }
            this.labels = tmpLabels;
            this.loadGraphData();
        },
        resetFilters: function() {
            this.pickedStatus = { name: 'choose', ids: null };
            this.filters[0].value = null;
        }
    },
    computed: {
        filterSearch: function() {
            return this.filters[0].value;
        },
    },
    mounted() {
        this.currentMonth = new Date().getMonth();
        this.fillMonthsArray();
    },
    watch: {
        filterSearch: function() {
            setTimeout(() => {
                this.$refs.searchComponentIncoming.buildRequest(this.filters[0]);
                this.$refs.searchComponentOutgoing.buildRequest(this.filters[0]);
            }, 400);
        },
        "pickedStatus.ids": function() {
            this.$refs.searchComponentIncoming.buildRequest({name: 'filter[status]', value: this.pickedStatus.ids});
            this.$refs.searchComponentOutgoing.buildRequest({name: 'filter[status]', value: this.pickedStatus.ids});
        }
    }
}
</script>
<style lang="scss" scoped>
@import 'resources/sass/_package-statuses.scss';
</style>