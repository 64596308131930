<template>
    <v-select 
        name="province"
        label="name" 
        :filterable="false" 
        :options="provinces" 
        v-model="province"
        :disabled="disabled"
        @search="onSearchProvinces"
    >
        <template #option="{ name }">
            {{name}}
        </template>
    </v-select>
</template>

<script>
    export default {
        data: function () {
            return {
                provinces: [],
                province: null,
            }
        },
        props: {
            value: null,
            country_id: null,
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        methods: {
            onSearchProvinces(search, loading) {
                loading(true);
                this.searchProvinces(loading, search, this);
            },
            searchProvinces: _.debounce((loading, search, vm) => {
                axios.get(vm.$root.route('ajax.provinces.index'), {
                            params: {
                                'filter[name]': search,
                                'country_id': vm.country_id,
                            }
                        }).then(function (response) {
                            vm.provinces = response.data;
                            loading(false);
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
            }, 350),
        },
        watch: {
            province: function () {
                this.$emit('input', this.province);
            },
            value: function () {
                this.province = this.value;
            },
            disabled: function() {
                this.province = null;
                this.provinces = [];
            },
            country_id: function() {
                this.province = null;
                this.provinces = [];
            }
        },
        mounted () {
            this.province = this.value;
        }
    }
</script>