import DashboardIndexPage from '../pages/dashboard/DashboardIndexPage.vue';

import PackageIndexPage from '../pages/package/PackageIndexPage.vue';
import PackageShowPage from '../pages/package/PackageShowPage.vue';
import PackageStepCreatePage from '../pages/package/PackageStepCreatePage.vue';

import EntityIndexPage from '../pages/entity/EntityIndexPage.vue';

import PaymentIndexPage from '../pages/payment/PaymentIndexPage.vue';
import PaymentShowPage from '../pages/payment/PaymentShowPage.vue';
import PaymentCreatePage from '../pages/payment/PaymentCreatePage.vue';

import ServiceIndexPage from '../pages/service/ServiceIndexPage.vue';
import ServiceShowPage from '../pages/service/ServiceShowPage.vue';

const routes = [
    {
        path: "/",
        name: "dashboard",
        component: DashboardIndexPage,
    },
    {
        path: "/packages",
        name: "packages-index",
        component: PackageIndexPage,
    },
    {
        path: "/packages/create",
        name: "packages-create",
        component: PackageStepCreatePage,
    },
    {
        path: "/packages/:uuid",
        name: "packages-show",
        component: PackageShowPage,
        props: true,
    },
    {
        path: "/entities",
        name: "entities-index",
        component: EntityIndexPage,
    },
    {
        path: "/payments",
        name: "payments-index",
        component: PaymentIndexPage,
    },
    {
        path: "/payments/create",
        name: "payments-create",
        component: PaymentCreatePage,
    },
    {
        path: "/payments/:uuid",
        name: "payments-show",
        component: PaymentShowPage,
        props: true,
    },
    {
        path: "/services",
        name: "services-index",
        component: ServiceIndexPage,
    },
    {
        path: "/services/:uuid",
        name: "services-show",
        component: ServiceShowPage,
        props: true,
    },
];



export default routes;